import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse, Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { reactLocalStorage } from 'reactjs-localstorage';

class Sidebar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userData: {}
    };
  }

  componentDidMount() {
    let userDatas = reactLocalStorage.get('credential');
    if (userDatas) {
      console.log("asd : ", userDatas);
      let dataUser = JSON.parse(userDatas);
      this.setState({ userData: dataUser });
    }

  }

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/form-elements', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },

      { path: '/membership', state: 'membershipMenuOpen' },
      { path: '/periode', state: 'membershipMenuOpen' },
      { path: '/studio', state: 'membershipMenuOpen' },
      { path: '/fasilitas', state: 'membershipMenuOpen' },
      { path: '/gallery', state: 'membershipMenuOpen' },
      { path: '/info', state: 'membershipMenuOpen' },


      { path: '/supplier', state: 'barangMenuOpen' },
      { path: '/satuan', state: 'barangMenuOpen' },
      { path: '/jenis', state: 'barangMenuOpen' },
      { path: '/barang', state: 'barangMenuOpen' },

      { path: '/schedule', state: 'ptMenuOpen' },
      { path: '/kontrak', state: 'ptMenuOpen' },
      { path: '/trainer', state: 'ptMenuOpen' },



      { path: '/payment', state: 'paymentMenuOpen' },

      { path: '/bmasuk', state: 'salesMenuOpen' },
      { path: '/bkeluar', state: 'salesMenuOpen' },
      { path: '/finance/income', state: 'salesMenuOpen' },
      { path: '/finance/expend', state: 'salesMenuOpen' },
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    }));

  }

  render() {
    const { userData } = this.state;
    let datacred = reactLocalStorage.get('credential');
    let creds = datacred ? JSON.parse(datacred) : {};
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
            <a className="sidebar-brand brand-logo" href="/dashboard"><img style={{ alignSelf: 'center', height: 53, width: 140 }} src={require('../../assets/images/logo.png')} alt="logo" /></a>
            <a className="sidebar-brand brand-logo-mini" href="/dashboard"><img style={{ width: 40, height: 40 }} src={require('../../assets/images/favicon.png')} alt="logo" /></a>
        </div>
        <ul className="nav">
          <li className="nav-item profile">
            <div className="profile-desc">
              <div className="profile-pic">
                <div className="count-indicator">
                  <img className="img-xs rounded-circle " src={creds.foto ? creds.foto : require('../../assets/images/member/user.png')} alt="profile" />
                  <span className="count bg-success"></span>
                </div>
                <div className="profile-name">
                  <h5 className="mb-0 font-weight-normal "><Trans className="ellipsis">{creds.nama}</Trans></h5>
                  <span><Trans>{
                    creds.role == 'admin' ? 'Super Admin' : creds.role == 'gudang' ? 'Admin' : 'Owner'
                  }</Trans></span>
                </div>
              </div>
              <Dropdown alignRight>
                <Dropdown.Toggle as="a" className="cursor-pointer no-caret">
                  <i className="mdi mdi-dots-vertical"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="sidebar-dropdown preview-list">
                  <Link to="/settings/data" className="dropdown-item preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-settings text-primary"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1 text-small"><Trans>Account settings</Trans></p>
                    </div>
                  </Link>
                  <div className="dropdown-divider"></div>
                  <Link to="/settings/ubahpassword" className="dropdown-item preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-onepassword  text-info"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1 text-small"><Trans>Change Password</Trans></p>
                    </div>
                  </Link>

                </Dropdown.Menu>
              </Dropdown>
            </div>
          </li>
          <li className="nav-item nav-category">
            <span className="nav-link"><Trans>Menu</Trans></span>
          </li>
          <li className={this.isPathActive('/dashboard') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/dashboard">
              <span className="menu-icon"><i className="mdi mdi-speedometer"></i></span>
              <span className="menu-title"><Trans>Dashboard</Trans></span>
            </Link>
          </li>

          <li className="nav-item nav-category">
            <span className="nav-link"><Trans>Master Data</Trans></span>
          </li>
          <li className={this.isPathActive('/membership') || this.isPathActive('/periode') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <div className={this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('membershipMenuOpen')} data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-account-multiple"></i>
              </span>
              <span className="menu-title"><Trans>Gym</Trans></span>
              <i className="menu-arrow"></i>
            </div>

            <Collapse in={this.state.membershipMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={this.isPathActive('/membership') ? 'nav-link active' : 'nav-link'} to="/membership/data"><Trans>Member</Trans></Link></li>

                  <li className="nav-item"> <Link className={this.isPathActive('/periode') ? 'nav-link active' : 'nav-link'} to="/periode/data"><Trans>Period</Trans></Link></li>

                  <li className="nav-item"> <Link className={this.isPathActive('/studio') ? 'nav-link active' : 'nav-link'} to="/studio/data"><Trans>Studio</Trans></Link></li>

                  <li className="nav-item"> <Link className={this.isPathActive('/fasilitas') ? 'nav-link active' : 'nav-link'} to="/fasilitas/data"><Trans>Facilities</Trans></Link></li>

                  <li className="nav-item"> <Link className={this.isPathActive('/gallery') ? 'nav-link active' : 'nav-link'} to="/gallery/data"><Trans>Gallery</Trans></Link></li>

                  <li className="nav-item"> <Link className={this.isPathActive('/info/data') ? 'nav-link active' : 'nav-link'} to="/info/data"><Trans>Annoucement</Trans></Link></li>
                </ul>
              </div>
            </Collapse>
          </li>


          <li className={this.isPathActive('/barang') || this.isPathActive('/supplier') || this.isPathActive('/satuan') || this.isPathActive('/jenis') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <div className={this.state.barangMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('barangMenuOpen')} data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-buffer"></i>
              </span>
              <span className="menu-title"><Trans>Products</Trans></span>
              <i className="menu-arrow"></i>
            </div>

            <Collapse in={this.state.barangMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={this.isPathActive('/barang') ? 'nav-link active' : 'nav-link'} to="/barang/data"><Trans>Product</Trans></Link></li>

                  <li className="nav-item"> <Link className={this.isPathActive('/supplier') || this.isPathActive('/supplier/add') ? 'nav-link active' : 'nav-link'} to="/supplier/data"><Trans>Supplier</Trans></Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/satuan') ? 'nav-link active' : 'nav-link'} to="/satuan/data"><Trans>Mount</Trans></Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/jenis') ? 'nav-link active' : 'nav-link'} to="/jenis/data"><Trans>Category</Trans></Link></li>
                </ul>
              </div>
            </Collapse>
          </li>

          <li className={this.isPathActive('/schedule') || this.isPathActive('/class') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <div className={this.state.ptMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('ptMenuOpen')} data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-book"></i>
              </span>
              <span className="menu-title"><Trans>Personal Training</Trans></span>
              <i className="menu-arrow"></i>
            </div>

            <Collapse in={this.state.ptMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={this.isPathActive('/trainer') ? 'nav-link active' : 'nav-link'} to="/trainer/data"><Trans>Trainer</Trans></Link></li>

                  <li className="nav-item"> <Link className={this.isPathActive('/kontrak') ? 'nav-link active' : 'nav-link'} to="/kontrak/data"><Trans>Contract</Trans></Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/schedule/data') || this.isPathActive('/schedule/add') ? 'nav-link active' : 'nav-link'} to="/schedule/data"><Trans>Schedule</Trans></Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/schedule/calendar') ? 'nav-link active' : 'nav-link'} to="/schedule/calendar"><Trans>Calendar</Trans></Link></li>
                </ul>
              </div>
            </Collapse>
          </li>

          <li className="nav-item nav-category">
            <span className="nav-link"><Trans>Finance & Transaction</Trans></span>
          </li>

          <li className={this.isPathActive('/bmasuk') || this.isPathActive('/bkeluar') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <div className={this.state.salesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('salesMenuOpen')} data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-cash"></i>
              </span>
              <span className="menu-title"><Trans>Sales & Finance</Trans></span>
              <i className="menu-arrow"></i>
            </div>

            <Collapse in={this.state.salesMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    <Link to="/bmasuk/data" className={this.isPathActive('/bmasuk') ? 'nav-link active' : 'nav-link'}><Trans>Buy Product</Trans></Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/bkeluar/add" className={this.isPathActive('/bkeluar') ? 'nav-link active' : 'nav-link'} ><Trans>Sell Product</Trans></Link>
                  </li>
                  {
                    creds.role == 'gudang' ? null : (
                      <>
                        <li className="nav-item">
                          <Link to="/finance/income/data" className={this.isPathActive('/finance/income') ? 'nav-link active' : 'nav-link'}><Trans>Income</Trans></Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/finance/expend/data" className={this.isPathActive('/finance/expend') ? 'nav-link active' : 'nav-link'} ><Trans>Expenditure</Trans></Link>
                        </li>
                      </>
                    )
                  }

                </ul>
              </div>
            </Collapse>
          </li>



          {
            creds.role == 'gudang' ? null : (
              <>
                <li className="nav-item nav-category">
                  <span className="nav-link"><Trans>Report</Trans></span>
                </li>
                <li className={this.isPathActive('/report') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                  <Link className="nav-link" to="/report/data">
                    <span className="menu-icon"><i className="mdi mdi-speedometer"></i></span>
                    <span className="menu-title"><Trans>Report</Trans></span>
                  </Link>
                </li>
                <li className="nav-item nav-category">
                  <span className="nav-link"><Trans>Settings</Trans></span>
                </li>
                {
                  creds.role == 'owner' ? null : (
                    <li className={this.isPathActive('/user') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                      <Link className="nav-link" to="/user/data">
                        <span className="menu-icon"><i className="mdi mdi-account"></i></span>
                        <span className="menu-title"><Trans>Users</Trans></span>
                      </Link>
                    </li>
                  )
                }
                <li className={this.isPathActive('/log') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                  <Link className="nav-link" to="/log">
                    <span className="menu-icon"><i className="mdi mdi-clock"></i></span>
                    <span className="menu-title"><Trans>User Log</Trans></span>
                  </Link>
                </li>
              </>
            )
          }

        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Sidebar);